import styled from "styled-components";
import {Link} from "gatsby";

export const ContentLibraryCardWrapper = styled.div`
  background-color: rgba(13, 65, 121, 0.7);
  padding: ${props => props.smallerPadding ? "5px" : "15px"};
  width: 100%;
  box-sizing: border-box;
  display: grid;
  cursor: pointer;

  transition: box-shadow 500ms;
  animation: fade-in 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;

  @keyframes fade-in {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &:hover {
    box-shadow: 0px 0px 19px 0px #4fa0e2;
  }

  @media screen and (max-width: 800px) {
    padding: 5px;
  }
`

export const ContentLibraryCardImg = styled.img`
  max-height: 1000px;
  height: auto;
  width: 100%;
  aspect-ratio: 16/9;
`

export const ContentLibraryHighlight = styled.div`
  margin-top: ${props => (props.smaller && !props.longtitle) ? "-60px" : "-85px"};
  height: ${props => (props.smaller && !props.longtitle) ? "60px" : "85px"};
  background: var(--secondary-brand);
  opacity: 0.90;

  @media screen and (max-width: 1100px) {
    margin-top: ${props => (props.smaller && !props.longtitle) ? "-60px" : "-95px"};
    height: ${props => (props.smaller && !props.longtitle) ? "60px" : "95px"};
  }
  
  @media screen and (max-width: 800px) {
    margin-top: -60px;
    height: 60px;
  }
`

export const ContentLibraryHighlightText = styled.div`
  margin-top: ${props => (props.smaller && !props.longtitle) ? "-60px" : "-85px"};
  height: ${props => (props.smaller && !props.longtitle) ? "60px" : "85px"};
  padding: ${props => props.smaller ? "5px 20px 5px 20px" : "10px 40px 10px 40px"};
  box-sizing: border-box;
  z-index: 100;

  @media screen and (max-width: 1100px) {
    margin-top: ${props => (props.smaller && !props.longtitle) ? "-60px" : "-95px"};
    height: ${props => (props.smaller && !props.longtitle) ? "60px" : "95px"};
  }
  
  @media screen and (max-width: 800px) {
    margin-top: -60px;
    height: 60px;
    padding: 5px 20px 5px 20px;
  }
`

export const ContentLibraryA = styled.a`
  color: var(--primary-brand);
  text-decoration: inherit;
`

export const ContentLibraryLink = styled(Link)`
  color: var(--primary-brand);
  text-decoration: inherit;
`