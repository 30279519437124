import React from 'react';

import {convertCategories, convertThemes} from "../../general/content-library/ContentLibraryFunctions";

import {
    CLChip, CLChipInline, CLChipInlineDelete,
    CLChipWrapper
} from "../../styled/pages/about/content-library/ContentLibraryComponents";

const ContentLibraryChips = ({locale, filtering, setFiltering, products}) => {

    return (
        <CLChipWrapper>
            <CLChipInline>
            {filtering.lang !== "" &&
            <CLChip
                variant="outlined"
                size="small"
                label={filtering.lang.toUpperCase()}
                onClick={() => setFiltering({...filtering, lang: ""})}
                onDelete={() => setFiltering({...filtering, lang: ""})}
            />
            }
            {filtering.search !== "" &&
            <CLChip
                variant="outlined"
                size="small"
                label={locale === "hu" ? "Keresés..." : "Search..."}
                onClick={() => setFiltering({...filtering, search: ""})}
                onDelete={() => setFiltering({...filtering, search: ""})}
            />
            }
            {filtering.theme !== "" &&
            <CLChip
                variant="outlined"
                size="small"
                label={convertThemes(locale, filtering.theme)}
                onClick={() => setFiltering({...filtering, theme: ""})}
                onDelete={() => setFiltering({...filtering, theme: ""})}
            />
            }
            {filtering.product !== "" &&
            <CLChip
                variant="outlined"
                size="small"
                label={products.find(f => f.slug === filtering.product).name}
                onClick={() => setFiltering({
                    ...filtering, product: ""
                })}
                onDelete={() => setFiltering({
                    ...filtering, product: ""
                })}
            />
            }
            {filtering.category !== "" &&
            <CLChip
                variant="outlined"
                size="small"
                label={convertCategories(locale, filtering.category)}
                onClick={() => setFiltering({...filtering, category: ""})}
                onDelete={() => setFiltering({...filtering, category: ""})}
            />
            }
            </CLChipInline>
            <CLChipInlineDelete>
                {(filtering.category !== "" || filtering.product !== "" || filtering.theme !== "" || filtering.search !== "" || filtering.lang !== "") &&
                <CLChip
                    delete="true"
                    variant="outlined"
                    size="small"
                    label={locale === "hu" ? "Szűrők törlése" : "Clear filters"}
                    onClick={() => setFiltering({
                        search: "",
                        category: "",
                        product: "",
                        lang: "",
                        theme: "",
                    })}
                    onDelete={() => setFiltering({
                        search: "",
                        category: "",
                        product: "",
                        lang: "",
                        theme: "",
                    })}
                />
                }
            </CLChipInlineDelete>
        </CLChipWrapper>
    );
};

export default ContentLibraryChips;