import React, {useEffect, useState} from 'react';
import {graphql} from "gatsby";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as queryString from "querystring";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import ContentLibraryCard from "../../components/cards/ContentLibraryCard";
import {
    convertCategories,
    convertThemes,
    filterItems,
    paginateArray,
    sortCategory,
    sortPiece
} from "../../components/general/content-library/ContentLibraryFunctions";
import ContentLibraryChips from "../../components/pages/about/ContentLibraryChips";

import {
    CardsSection,
    ContentLibraryMenuButton, ContentLibraryMenuItem,
    ContentLibrarySideInnerWrapper,
    ContentLibrarySideSearch,
    ContentLibrarySideWrapper
} from "../../components/styled/pages/about/content-library/ContentLibraryComponents";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";
import {Button, ButtonContainer} from "../../components/styled/button/ButtonComponents";
import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import {SelectContentLibrary} from "../../components/styled/forms/FormComponents";

import circuit from "../../../static/assets/animations/circuit/circuit01.json";
import searchIcon from "../../../static/assets/search.svg";

const ContentLibrary = ({data}) => {

    const {content_library} = data;
    const {products} = data.allStrapiContentLibraryProducts;
    const {downloads} = data.allStrapiContentLibraryDownloads;
    const {nodes} = data.allStrapiComponentsData;
    const categories = nodes[0].filters.find(e => e.apiId === "filter-list").schema.attributes.category.enum;
    const lang = nodes[0].filters.find(e => e.apiId === "filter-list").schema.attributes.lang.enum;
    const themes = nodes[0].filters.find(e => e.apiId === "filter-list").schema.attributes.theme.enum;
    const balasysProducts = products.filter(f => f.category === "balasys").filter(f => content_library.locale === "hu" ? f.slug !== "proxedo-network-security" : (f.slug !== "zorp" && f.slug !== "zorp-malware-detection")).sort(sortPiece);
    const sortedCategories = categories.map((item) => ({
        "name": item,
        "count": downloads.filter(f => f.filters.category === item).length
    })).sort(sortCategory);
    const oneIdentityProducts = products.filter(f => f.category === "one_identity").sort(sortPiece);
    const questProducts = products.filter(f => f.category === "quest").sort(sortPiece);

    const [filtering, setFiltering] = useState(
        {
            search: "",
            category: "",
            product: "",
            lang: "",
            theme: "",
        })

    const searchParams = queryString.stringify({
        search: filtering.search,
        category: filtering.category,
        product: filtering.product,
        lang: filtering.lang,
        theme: filtering.theme
    });

    useEffect(() => {
        const parsedUrl = queryString.parse(window.location.search);
        setFiltering({
            search: parsedUrl['?search'] || "",
            category: parsedUrl.category || "",
            product: parsedUrl.product || "",
            lang: parsedUrl.lang || "",
            theme: parsedUrl.theme || "",
        })
    }, []);

    useEffect(() => {
        window.history.replaceState(
            null,
            '',
            window.location.origin + window.location.pathname + `?${searchParams}`
        )
    }, [searchParams]);

    const [pageSize, setPageSize] = useState(7);
    const [categorySize, setCategorySize] = useState(5);
    const [questPageSize, setQuestPageSize] = useState(5);
    const [oneIdentityPageSize, setOneIdentityPageSize] = useState(5);

    const paginatedItems = paginateArray(filterItems(filtering, downloads), pageSize);
    const paginatedCategories = paginateArray(sortedCategories, categorySize);
    const paginatedQuest = paginateArray(questProducts, questPageSize);
    const paginatedOneIdentity = paginateArray(oneIdentityProducts, oneIdentityPageSize);

    const [clickCounter, setClickCounter] = useState(0);
    const [clickCounter2, setClickCounter2] = useState(0);

    function categorySelect(item) {
        if (clickCounter === 0) {
            setFiltering({...filtering, category: item})
            setClickCounter(1);
        } else if (clickCounter === 1 && filtering.category === item) {
            setFiltering({...filtering, category: ""})
            setClickCounter(0);
        } else {
            setFiltering({...filtering, category: item})
        }
    }


    function productSelect(item, name) {
        if (clickCounter2 === 0) {
            setFiltering({...filtering, product: item})
            setClickCounter2(1);
        } else if (clickCounter2 === 1 && filtering.product === item) {
            setFiltering({...filtering, product: ""})
            setClickCounter2(0);
        } else {
            setFiltering({...filtering, product: item})
        }
    }

    return (
        <Layout lang={content_library.locale} seo={content_library.SEO}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid customBackground="middle" start wide>
                <Col>
                    <Title color="white" textAlign="center">
                        {content_library.title}
                    </Title>
                    <Body color="body-text" textAlign="center">
                        {content_library.subtitle}
                    </Body>
                </Col>
                <Col span={3}>
                    <ContentLibrarySideWrapper>
                        <ContentLibrarySideInnerWrapper>
                            <SelectContentLibrary variant="outlined" size="small" autoheight="true" fullWidth>
                                <InputLabel id="lang-label">
                                    {content_library.locale === "hu" ? "Nyelv" : "Language"}
                                </InputLabel>
                                <Select
                                    native
                                    name="lang"
                                    id="lang"
                                    value={filtering.lang}
                                    onChange={(e) => setFiltering({...filtering, lang: e.target.value})}
                                    label={content_library.locale === "hu" ? "Nyelv" : "Language"}
                                >
                                    <option value="" aria-label="None"/>
                                    {lang.map(item => (
                                        <option value={item} key={item}>{item.toUpperCase()}</option>
                                    ))}
                                </Select>
                            </SelectContentLibrary>
                        </ContentLibrarySideInnerWrapper>
                    </ContentLibrarySideWrapper>
                    <ContentLibrarySideWrapper>
                        <ContentLibrarySideInnerWrapper>
                            <SelectContentLibrary variant="outlined" size="small" autoheight="true" fullWidth>
                                <InputLabel id="theme-label">
                                    {content_library.locale === "hu" ? "Téma" : "Theme"}
                                </InputLabel>
                                <Select
                                    native
                                    name="theme"
                                    id="theme"
                                    value={filtering.theme}
                                    onChange={(e) => setFiltering({...filtering, theme: e.target.value})}
                                    label={content_library.locale === "hu" ? "Téma" : "Theme"}
                                >
                                    <option value="" aria-label="None"/>
                                    {themes.map(item => (
                                        <option value={item}
                                                key={item}>{convertThemes(content_library.locale, item)}</option>
                                    ))}
                                </Select>
                            </SelectContentLibrary>
                        </ContentLibrarySideInnerWrapper>
                    </ContentLibrarySideWrapper>
                    <ContentLibrarySideWrapper>
                        <ContentLibrarySideInnerWrapper padding>
                            <Body bold color="primary-brand">
                                {content_library.locale === "hu" ? "Kategóriák" : "Categories"}
                            </Body>
                            {paginatedCategories.map((item) => (
                                <ContentLibraryMenuButton key={item.name} onClick={() => categorySelect(item.name)}>
                                    <ContentLibraryMenuItem active={item.name === filtering.category}
                                                            bold={item.name === filtering.category} small>
                                        {convertCategories(content_library.locale, item.name)} ({item.count})
                                    </ContentLibraryMenuItem>
                                </ContentLibraryMenuButton>
                            ))}
                            {sortedCategories.length > paginatedCategories.length &&
                            <ContentLibraryMenuButton onClick={() => setCategorySize(categorySize + 5)}>
                                <ContentLibraryMenuItem small bold>
                                    {content_library.locale === "hu" ? "Több betöltése..." : "Show more..."}
                                </ContentLibraryMenuItem>
                            </ContentLibraryMenuButton>
                            }
                        </ContentLibrarySideInnerWrapper>
                    </ContentLibrarySideWrapper>
                    <ContentLibrarySideWrapper>
                        <ContentLibrarySideInnerWrapper padding>
                            <Body bold color="primary-brand">
                                {content_library.locale === "hu" ? "Balasys termékek" : "Balasys products"}
                            </Body>
                            {balasysProducts.map((item) => (
                                <ContentLibraryMenuButton key={item.slug}
                                                          onClick={() => productSelect(item.slug, item.name)}>
                                    <ContentLibraryMenuItem active={item.slug === filtering.product}
                                                            bold={item.slug === filtering.product} small>
                                        {item.name} ({item.downloads.length})
                                    </ContentLibraryMenuItem>
                                </ContentLibraryMenuButton>
                            ))}
                            <Body bold color="primary-brand" marginTop="smaller">
                                {content_library.locale === "hu" ? "One Identity termékek" : "One Identity products"}
                            </Body>
                            {paginatedOneIdentity.map((item) => (
                                <ContentLibraryMenuButton key={item.slug}
                                                          onClick={() => productSelect(item.slug, item.name)}>
                                    <ContentLibraryMenuItem active={item.slug === filtering.product}
                                                            bold={item.slug === filtering.product} small>
                                        {item.name} ({item.downloads.length})
                                    </ContentLibraryMenuItem>
                                </ContentLibraryMenuButton>
                            ))}
                            {oneIdentityProducts.length > paginatedOneIdentity.length &&
                            <ContentLibraryMenuButton onClick={() => setOneIdentityPageSize(oneIdentityPageSize + 5)}>
                                <ContentLibraryMenuItem small bold>
                                    {content_library.locale === "hu" ? "Több betöltése..." : "Show more..."}
                                </ContentLibraryMenuItem>
                            </ContentLibraryMenuButton>
                            }
                            <Body bold color="primary-brand" marginTop="smaller">
                                {content_library.locale === "hu" ? "Quest termékek" : "Quest products"}
                            </Body>
                            {paginatedQuest.map((item) => (
                                <ContentLibraryMenuButton key={item.slug}
                                                          onClick={() => productSelect(item.slug, item.name)}>
                                    <ContentLibraryMenuItem active={item.slug === filtering.product}
                                                            bold={item.slug === filtering.product} small>
                                        {item.name} ({item.downloads.length})
                                    </ContentLibraryMenuItem>
                                </ContentLibraryMenuButton>
                            ))}
                            {questProducts.length > paginatedQuest.length &&
                            <ContentLibraryMenuButton onClick={() => setQuestPageSize(questPageSize + 5)}>
                                <ContentLibraryMenuItem small bold>
                                    {content_library.locale === "hu" ? "Több betöltése..." : "Show more..."}
                                </ContentLibraryMenuItem>
                            </ContentLibraryMenuButton>
                            }
                        </ContentLibrarySideInnerWrapper>
                    </ContentLibrarySideWrapper>
                </Col>
                <Col span={9}>
                    <ContentLibrarySideWrapper>
                        <ContentLibrarySideInnerWrapper>
                            <ContentLibrarySideSearch
                                variant="outlined"
                                size="small"
                                label={content_library.locale === "hu" ? "Keresés" : "Search"}
                                value={filtering.search}
                                onChange={(e) => setFiltering({...filtering, search: e.target.value})}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <img src={searchIcon} alt="search-icon"/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ContentLibrarySideInnerWrapper>
                    </ContentLibrarySideWrapper>
                    <ContentLibraryChips
                        locale={content_library.locale}
                        filtering={filtering}
                        setFiltering={setFiltering}
                        products={products}
                    />
                    <CardsSection>
                        {paginatedItems.map((item, index) => (index === 0 ?
                                <Col key={index}>
                                    <ContentLibraryCard
                                        avatar={item.avatar.url}
                                        title={item.title}
                                        category={convertCategories(content_library.locale, item.filters.category)}
                                        slug={item.gated_content ? "/" + item.filters.lang + "/content-library/" + item.slug : undefined}
                                        ss_link={item.ss_tracking_link}
                                        gated={item.gated_content}
                                    />
                                </Col>
                                :
                                <Col span={6} key={index}>
                                    <ContentLibraryCard
                                        avatar={item.avatar.url}
                                        title={item.title.length <= 40 ? item.title : (item.filters.lang === "ru" ? item.title.slice(0, 32) + "..." : item.title.slice(0, 40) + "...")}
                                        category={convertCategories(content_library.locale, item.filters.category)}
                                        isSmall
                                        slug={item.gated_content ? "/" + item.filters.lang + "/content-library/" + item.slug : undefined}
                                        ss_link={item.ss_tracking_link}
                                        gated={item.gated_content}
                                    />
                                </Col>
                        ))}
                        {paginatedItems.length === 0 &&
                        <Col grid center>
                            <Body color="body-text" bold textAlign="center" marginTop="large">
                                {content_library.locale === "hu" ? "Nincs találat." : "No items found."}
                            </Body>
                        </Col>
                        }
                        {paginatedItems.length >= 7 &&
                        <Col grid center>
                            <ButtonContainer>
                                <Button onClick={() => setPageSize(pageSize + 6)}
                                        disabled={paginatedItems.length === filterItems(filtering, downloads).length}>
                                    {content_library.locale === "hu" ? "Több betöltése..." : "Show more..."}
                                </Button>
                            </ButtonContainer>
                        </Col>
                        }
                    </CardsSection>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleContentLibrary($locale: String) {
         content_library: strapiContentLibrary(locale: { eq: $locale }) {
          locale
          title
          subtitle
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
         }
         allStrapiContentLibraryProducts {
            products: nodes {
              category
              name
              slug
              downloads {
                id
              }
            }
         }
         allStrapiContentLibraryDownloads {
            downloads: nodes {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
                }
            }
         }
         allStrapiComponentsData {
            nodes {
              filters: data {
                apiId
                schema {
                  attributes {
                    category {
                      enum
                    }
                    lang {
                      enum
                    }
                    theme {
                      enum
                    }
                  }
                }
              }
            }
          }
   }
 `

export default ContentLibrary;