import React from 'react';

import {OnlyDesktop, OnlyMobile} from "../styled/views/MobileOrDesktop";
import {
    ContentLibraryA,
    ContentLibraryCardImg,
    ContentLibraryCardWrapper,
    ContentLibraryHighlight,
    ContentLibraryHighlightText
} from "../styled/cards/ContentLibraryCardComponents";
import {Body, Header3, LineClamp} from "../styled/typography/Typography";

const ContentLibraryCard = ({avatar, title, category, slug, ss_link, isSmall, longTitle, gated}) => {
    return (
        <>
            {!ss_link && gated &&
            <ContentLibraryA href={slug} target="_blank" rel="noreferrer">
                <ContentLibraryCardWrapper smallerPadding={isSmall}>
                    <ContentLibraryCardImg src={avatar} alt={title}/>
                    <ContentLibraryHighlight smaller={isSmall} longtitle={longTitle}/>
                    {!isSmall &&
                    <ContentLibraryHighlightText>
                        <LineClamp lines={1}>
                            <Header3 color="primary-brand">{title}</Header3>
                        </LineClamp>
                        <Body color="white" upper small>{category}</Body>
                    </ContentLibraryHighlightText>
                    }
                    {isSmall &&
                    <ContentLibraryHighlightText smaller={isSmall} longtitle={longTitle}>
                        <OnlyDesktop>
                            <LineClamp lines={1}>
                                <Body color="primary" bold small>
                                    {title}
                                </Body>
                            </LineClamp>
                            <Body color="white" small upper>
                                {category}
                            </Body>
                        </OnlyDesktop>
                        <OnlyMobile>
                            <LineClamp lines={1}>
                                <Header3 color="primary-brand">
                                    {title}
                                </Header3>
                            </LineClamp>
                            <Body color="white" small upper>
                                {category}
                            </Body>
                        </OnlyMobile>
                    </ContentLibraryHighlightText>
                    }
                </ContentLibraryCardWrapper>
            </ContentLibraryA>
            }
            {ss_link && !gated &&
            <ContentLibraryA href={ss_link} target="_blank" rel="noreferrer">
                <ContentLibraryCardWrapper smallerPadding={isSmall}>
                    <ContentLibraryCardImg src={avatar} alt={title}/>
                    <ContentLibraryHighlight smaller={isSmall} longtitle={longTitle}/>
                    {!isSmall &&
                    <ContentLibraryHighlightText>
                        <LineClamp lines={1}>
                            <Header3 color="primary-brand">{title}</Header3>
                        </LineClamp>
                        <Body color="white" upper small>{category}</Body>
                    </ContentLibraryHighlightText>
                    }
                    {isSmall &&
                    <ContentLibraryHighlightText smaller={isSmall} longtitle={longTitle}>
                        <OnlyDesktop>
                            <LineClamp lines={1}>
                                <Body color="primary" bold>
                                    {title}
                                </Body>
                            </LineClamp>
                            <Body color="white" small upper>
                                {category}
                            </Body>
                        </OnlyDesktop>
                        <OnlyMobile>
                            <LineClamp lines={1}>
                                <Header3 color="primary-brand">
                                    {title}
                                </Header3>
                            </LineClamp>
                            <Body color="white" small upper>
                                {category}
                            </Body>
                        </OnlyMobile>
                    </ContentLibraryHighlightText>
                    }
                </ContentLibraryCardWrapper>
            </ContentLibraryA>
            }
        </>
    );
};

export default ContentLibraryCard;